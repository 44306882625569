import $ from 'jquery'
import SmoothScroll from 'smoothscroll-polyfill'

const $token = $('meta[name="csrf-token"]').attr('content');
let message = "Afficher son numéro";
let num = "02 47 47 15 92";
let load = false;
let modal = document.getElementById('alert');

if (!('scrollBehavior' in document.documentElement.style)) {
    SmoothScroll.polyfill();
}


/**
 * Bascule l'affichage du numéro/message
 */
$('.button-show-num').click(function(){

    //Ajoute ou supprimer en fonction de s'il est déjà présent ou non
    $(this).toggleClass("display-num");

    //Si il a la class "display-num", afficher le numéro
    if($(this).hasClass('display-num')){
        $(this).html(num);
    }else{
        //Sinon afficher le message
        $(this).html(message);
    }
});


/**
 * Soumettre le formulaire en XHR
 */
$('.form-rappel').submit(function(e){

    e.preventDefault();

    //Récupère la div sur laquelle on a cliqué
    let $div = $(this);

    //Récupère le numéro de téléphone
    let numtel = $div.children('.input-call').val().replace(/ /g,"");

    // Récupère l'url d'action du formulaire
    let url = $div.attr('action');

    let $messageRes = $('.message-res')
    //Load vérifie si le formulaire fait sont traitement Bloque le button "Ok" tant que success n'a pas renvoyé sa réponse. Empêche le spam
    if(!load){
        load = true;
        $.ajax({
            //Url de traitement des données
            url: url,
            type: 'POST',
            //Numéro de téléphone et token envoyés en post
            data: {
                tel: numtel,
                _token: $token
            },
            dataType: "json",
            success: function(data){
                //Vide l'input
                $div.children('.input-call').val("");
                //Affiche si le téléphone est envoyé
                $messageRes.remove();
                $div.parent().append('<small class="message-res">' + data.message + '</small>');
                //Réponse envoyé
                load = false;
            },
            error: function(xhr, status, error) {
                $.each(xhr.responseJSON.errors, function(i, obj){
                    $.each(obj, function(key, value){

                        if($messageRes.html() !== value){

                            $messageRes.remove();
                            $div.parent().append('<small class="message-res txt-error">' + value + '</small>');
                        }
                    });
                });
                //Réponse envoyé
                load = false;
            }
        });
    }
});


/**
 * Masquer ou retirer la modale
 */
if(modal){
    let $modalTop = $('#modal-top')

    $('#btn-close').click( function() {
        $modalTop.remove()
    })

    $modalTop.fadeOut(5000);
}
